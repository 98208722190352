<template>
  <div>
    <div class="row page-titles mx-0">
      <div class="col-sm-6 p-md-0">
        <div class="welcome-text">
          <h4>Other Fees</h4>
        </div>
      </div>
      <div class="col-sm-6 p-md-0 justify-content-sm-end mt-2 mt-sm-0 d-flex">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a href="/">Home</a></li>
          <li class="breadcrumb-item">
            <router-link :to="{ name: 'Dashboard-Home' }"
              >Dashboard</router-link
            >
          </li>
          <li class="breadcrumb-item">
            <router-link :to="{ name: 'Student-Affairs' }"
              >Student Affairs</router-link
            >
          </li>
          <li class="breadcrumb-item active">Other Fees</li>
        </ol>
      </div>
    </div>

    <!-- Student Info -->
    <div class="row tab-content">
      <div id="" class="tab-pane fade active show col-lg-6">
        <div class="card top-red-border">
          <div class="card-header">
            <h4 class="card-title">Student Info</h4>
          </div>
          <div class="card-body">
            <div class="basic-form">
              <div class="form-group row">
                <label class="col-md-3 col-form-label">Code</label>
                <label class="col-md-9 col-form-label">
                  {{ student.code }}
                </label>
              </div>

              <div class="form-group row">
                <label class="col-md-3 col-form-label">Name</label>
                <label class="col-md-9 col-form-label">
                  {{ student.firstname }} {{ student.middlename }}
                  {{ student.lastname }}
                </label>
              </div>

              <div class="form-group row">
                <label class="col-md-3 col-form-label">Email</label>
                <label class="col-md-9 col-form-label">
                  {{ student.email }}
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row tab-content">
      <div id="" class="tab-pane fade active show col-lg-12">
        <div class="card top-red-border">
          <div class="card-header">
            <h4 class="card-title">List All</h4>
            <router-link
              v-can="'add_other_fees'"
              :to="{
                name: 'Student-Affairs-Other-Fees-Create',
                params: { id: student.id }
              }"
              class="btn btn-primary"
              >Add New</router-link
            >
          </div>
          <div class="card-body">
            <div class="table-responsive">
              <table
                class="
                  table table-bordered table-striped
                  verticle-middle
                  table-responsive-sm
                "
              >
                <thead>
                  <tr>
                    <th scope="col">Type</th>
                    <th scope="col">Service</th>
                    <th scope="col">Year</th>
                    <th scope="col">Installment Amount</th>
                    <th scope="col">Amount</th>
                    <th scope="col">Reason</th>
                    <th scope="col">Created By</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody v-if="other_fees.length">
                  <tr v-for="fees in other_fees" :key="fees.id">
                    <td>{{ fees.type }}</td>
                    <td>{{ fees.installment.service }}</td>
                    <td>{{ fees.installment.year }}</td>
                    <td>{{ fees.installment.amount }}</td>
                    <td>{{ fees.amount }}</td>
                    <td>{{ fees.reason }}</td>
                    <td>{{ fees.created_by }}</td>
                    <td>
                      <span>
                        <router-link
                          v-can="'edit_other_fees'"
                          :to="{
                            name: 'Student-Affairs-Other-Fees-Edit',
                            params: { id: fees.id },
                          }"
                          title="Edit"
                          class="mr-4"
                          ><i class="fa fa-pencil color-muted"></i>
                        </router-link>
                        <a
                          v-can="'delete_other_fees'"
                          @click.prevent="deleteFee(fees.id)"
                          href="javascript:void()"
                          title="Delete"
                          ><i class="fa fa-close color-danger"></i
                        ></a>
                      </span>
                    </td>
                  </tr>
                </tbody>
                <tbody v-else>
                  <tr>
                    <td style="text-align: center" colspan="8">
                      No Data Available.
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import useOtherFees from "@/composables/other_fees";
import { onMounted, onBeforeMount, inject } from "vue";
import { useRouter } from "vue-router";
export default {
  props: {
    student_id: {
      required: true,
      type: Number,
    },
  },
  setup(props) {
    const swal = inject("$swal");
    const hasPermission = inject("hasPermission");
    const router = useRouter();

    onBeforeMount(() => {
      //first: check the user Permission
      if (!hasPermission("access_other_fees"))
        router.push({
          name: "401",
        });
    });

    const {
      getOtherFees,
      deleteOtherFee,
      other_fees,
      student,
      lists,
      msgs,
      errors,
    } = useOtherFees();

    onMounted(() => {
      getOtherFees({ student_id: props.student_id });
    });

    const deleteFee = async (id) => {
      swal
        .fire({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, delete it!",
        })
        .then((result) => {
          if (result.isConfirmed) {
            deleteOtherFee(id).then(() => {
              swal.fire("Deleted!", "The data has been deleted successfully.", "success");
              getOtherFees();
            });
          }
        });
    };

    return {
      deleteFee,
      other_fees,
      student,
      lists,
      msgs,
      errors,
    };
  },
};
</script>